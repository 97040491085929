<template>
  <general-function-popup class="action-require-multi-popup" v-if="isShow">
    <div slot="title" class="title">
      <button class="hidden">
        <vue-material-icon name="keyboard_arrow_left" :size="30" />
      </button>
      <h2>점검 현황 상세</h2>
      <button class="close-button" @click="onClosePopup">
        <vue-material-icon name="close" :size="24" />
      </button>
    </div>
    <div slot="contents" class="contents">
      <ul class="action-status-list">
        <li>
          <span>점검 현황</span>
          <span :value="checkHistoryItem['chkHistItemState']">
            {{ checkHistoryItemStateName[checkHistoryItem['chkHistItemState']] }}
          </span>
        </li>
        <li>
          <span>점검 항목</span>
          <span>[{{ checkRequiredTypeName[checkHistoryItem['chkRequireType']] }}] {{ checkHistoryItem['name'] }}</span>
        </li>
      </ul>
      <ul class="request-action-list">
        <li v-for="(actionRequired, index) in checkHistoryItem['actionRequireds']" :key="index">
          <div class="request">
            <h3>특이 사항</h3>
            <div class="request-action-box">
              <div class="status-header">
                <span class="status-color-box" :class="actionStatusClassName(actionRequired)" />
                <span class="text-overflow-ellipsis">
                  {{ actionRequired['reqDT'] | localDateYYYYMMDDTime }}
                  {{ getUserName(actionRequired['requesterId']) }}
                </span>
              </div>
              <audio-play
                v-if="getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'audio')"
                :audio-src="getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'audio').rscUrl"
                :descriptions="['리얼웨어에서 입력된 특이사항은 음성 녹음 파일로 확인할 수 있습니다.']"
              />
              <label v-else>
                <textarea readonly :value="actionRequired['reqDesc']" />
              </label>
              <div
                class="image-wrapper"
                :class="{ 'none-image': !getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'img') }"
              >
                <p v-if="!getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'img')">
                  등록된 이미지가 없습니다.
                </p>
                <action-require-image-box
                  class="background-image"
                  :rsc-file="getFileInRscFiles(actionRequired['actionRequiredRscFiles'], 'img')"
                />
              </div>
            </div>
          </div>
          <div class="action">
            <div class="action-header">
              <h3>조치</h3>
              <div>
                <button-basic
                  v-if="isIncludeEditingItemId(actionRequired['id']) && actionRequired['actDT']"
                  :width="60"
                  :height="32"
                  color="blue-border-1"
                  @click="onClickCancelAction(actionRequired)"
                >
                  취소
                </button-basic>
                <button-basic
                  v-if="isIncludeEditingItemId(actionRequired['id'])"
                  :width="60"
                  :height="32"
                  :loading-dot="isLoadingItemId === actionRequired['id']"
                  @click="onClickSaveAction(actionRequired)"
                  :disabled="!isFilledAllRequiredField(actionRequired['actDesc'])"
                >
                  저장
                </button-basic>
                <button-basic
                  v-else-if="showEditButton(actionRequired)"
                  :width="60"
                  :height="32"
                  @click="onClickEditAction(actionRequired)"
                >
                  수정
                </button-basic>
              </div>
            </div>
            <div
              class="request-action-box"
              :class="{
                'action-edit-mode': isIncludeEditingItemId(actionRequired['id']),
                'action-focus-mode': isFocusedTextarea === actionRequired['id'],
              }"
            >
              <div class="status-header" v-if="!isIncludeEditingItemId(actionRequired['id'])">
                <span>
                  {{ actionRequired['actDT'] | localDateYYYYMMDDTime }} {{ getUserName(actionRequired['acterId']) }}
                </span>
              </div>
              <audio-play
                v-if="getFileInRscFiles(actionRequired['actionRscFiles'], 'audio')"
                :audio-src="getFileInRscFiles(actionRequired['actionRscFiles'], 'audio').rscUrl"
                :descriptions="[
                  '리얼웨어에서 입력된 조치 내용은 음성 녹음 파일로 확인할 수 있습니다.',
                  '리얼웨어에서 음성 녹음 파일로 저장된 조치 내용은 수정할 수 없습니다.',
                ]"
              />
              <label v-else>
                <textarea
                  v-model="actionRequired['actDesc']"
                  autocomplete="off"
                  :maxlength="500"
                  placeholder="조치한 내용을 입력하세요."
                  :readonly="!isIncludeEditingItemId(actionRequired['id'])"
                  @focus="toggleIsFocusedTextarea(actionRequired['id'])"
                  @blur="toggleIsFocusedTextarea(0)"
                />
              </label>
              <div
                class="image-wrapper"
                :class="{ 'none-image': !getFileInRscFiles(actionRequired['actionRscFiles'], 'img') }"
              >
                <template v-if="!getFileInRscFiles(actionRequired['actionRscFiles'], 'img')">
                  <file-basic
                    v-if="isIncludeEditingItemId(actionRequired['id'])"
                    :id="`action-file-${actionRequired.id}`"
                    accept=".jpg, .png"
                    color="dark"
                    size="s"
                    :width="150"
                    @change="onSelectFile($event, actionRequired)"
                  >
                    <plus-icon :size="16" />
                    이미지 등록
                  </file-basic>
                  <p v-else>등록된 이미지가 없습니다.</p>
                </template>
                <template v-else>
                  <action-require-image-box
                    class="background-image"
                    :rsc-file="getFileInRscFiles(actionRequired['actionRscFiles'], 'img')"
                  />
                  <span>
                    {{ getFileInRscFiles(actionRequired['actionRscFiles'], 'img').name }}
                  </span>
                  <button-basic
                    v-if="isIncludeEditingItemId(actionRequired['id'])"
                    color="dark"
                    circle="18"
                    @click="
                      onDeletePhoto(
                        actionRequired,
                        index,
                        getFileInRscFiles(actionRequired['actionRscFiles'], 'img').id,
                      )
                    "
                    class="delete-button"
                  >
                    <close-icon />
                  </button-basic>
                </template>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </general-function-popup>
</template>
<script>
import { mapActions } from 'vuex';
import checkHistoryItemState from '@/const/checkHistoryItemState';
import checkRequiredType from '@/const/checkRequiredType';
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import fileUpload from '@/asset/js/fileUpload';
import ActionRequireImageBox from '@/component/popup/actionRequirePopup/actionRequireImageBox/ActionRequireImageBox';
import getUserName from '@/asset/js/getUserName';
import AudioPlay from '@/component/audioPlay/AudioPlay';
import getFileInRscFiles from '@/asset/js/getFileInRscFiles';

export default {
  name: 'ActionRequireMultiPopup',
  props: {
    isShow: {
      type: Boolean,
      required: true,
    },
    checkHistoryItem: {
      type: Object,
      required: true,
    },
    userNameList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      checkRequiredTypeName: checkRequiredType,
      checkHistoryItemStateName: checkHistoryItemState,
      imageUrl: '',
      allFilename: '',
      fileBase64: '',
      selectedActionRequired: {},
      editingActionDescription: '',
      showedPopupName: '',
      expandingImageSrc: '',
      isLoadingItemId: 0,
      editingItemIdList: [],
      editingActionRscFilesList: [],
      editingActionDescList: [],
      isFocusedTextarea: 0,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {
    fileBase64() {
      if (this.fileBase64) {
        this.postActionRscFiles({
          name: this.allFilename,
          bs64Strfile: this.fileBase64,
          chkObjectId: this.$route.params.eId,
          type: 'ActImg',
        });
      }
    },
    checkHistoryItem() {
      for (let i = 0; i < this.checkHistoryItem.actionRequireds.length; i++) {
        if (!this.checkHistoryItem.actionRequireds[i].actDT) {
          this.onAddEditingItemIdList(this.checkHistoryItem.actionRequireds[i].id);
        }
      }
    },
  },
  methods: {
    ...mapActions('actionRequireds', ['PUT_ACTION_REQUIREDS', 'POST_ACTION_REQUIREDS']),
    ...mapActions('actionRscFiles', ['POST_ACTION_RSC_FILES', 'DELETE_ACTION_RSC_FILES']),
    showEditButton(actionRequired) {
      let isNotIncludeAudio = true;
      for (let i = 0; i < actionRequired.actionRscFiles.length; i++) {
        if (actionRequired.actionRscFiles[i].type === 'audio') {
          isNotIncludeAudio = false;
        }
      }
      return !this.isIncludeEditingItemId(actionRequired['id']) && isNotIncludeAudio;
    },
    getFileInRscFiles(rscFiles, typeValue) {
      return getFileInRscFiles(rscFiles, typeValue);
    },
    toggleIsFocusedTextarea(id) {
      this.isFocusedTextarea = id;
    },
    isFilledAllRequiredField(description) {
      return description;
    },
    actionStatusClassName(actionRequire) {
      return actionRequire.actDT ? 'all-action' : 'part-action';
    },
    onSelectFile(e, actionRequired) {
      fileUpload(this, e.target, true, false);
      this.selectedActionRequired = actionRequired;
    },
    onClosePopup() {
      this.$emit('close');
      this.editingItemIdList = [];
      this.editingActionRscFilesList = [];
      this.editingActionDescList = [];
    },
    togglePopup(popupName) {
      this.showedPopupName = popupName || '';
    },
    onDeletePhoto(actionRequired, index, rscFileId) {
      this.$set(this.checkHistoryItem.actionRequireds, index, {
        ...this.checkHistoryItem.actionRequireds[index],
        rscFileIds: [],
        actionRscFiles: actionRequired.actionRscFiles.map((file) => {
          return file.id !== rscFileId;
        }),
      });
    },
    onAddEditingItemIdList(id) {
      this.editingItemIdList.push(id);
    },
    onDeleteEditingItemIdList(id) {
      this.editingItemIdList = this.editingItemIdList.filter((itemId) => itemId !== id);
    },
    onClickEditAction(actionRequired) {
      this.onAddEditingItemIdList(actionRequired.id);
      this.editingActionRscFilesList.push({
        id: actionRequired.id,
        actionRscFiles: [...actionRequired.actionRscFiles],
      });
      this.editingActionDescList.push({
        id: actionRequired.id,
        actDesc: actionRequired.actDesc,
      });
    },
    onClickCancelAction(actionRequired) {
      this.onDeleteEditingItemIdList(actionRequired.id);
      actionRequired.actionRscFiles = this.editingActionRscFilesList.find(
        (actionRscFiles) => actionRscFiles.id === actionRequired.id,
      ).actionRscFiles;
      actionRequired.actDesc = this.editingActionDescList.find(
        (actionDesc) => actionDesc.id === actionRequired.id,
      ).actDesc;
    },
    createActionReqBody(actionRequired) {
      const action = { actDesc: actionRequired['actDesc'].trim() };
      if (actionRequired['rscFileIds'] && actionRequired['rscFileIds'].length > 0) {
        action.rscFileIds = actionRequired['rscFileIds'];
      } else if (getFileInRscFiles(actionRequired['actionRscFiles'], 'img')) {
        action.rscFileIds = [getFileInRscFiles(actionRequired['actionRscFiles'], 'img').id];
      } else {
        action.rscFileIds = [];
      }
      return action;
    },
    async onClickSaveAction(actionRequired) {
      try {
        this.isLoadingItemId = actionRequired['id'];
        const action = this.createActionReqBody(actionRequired);
        let resData = {};
        if (actionRequired['actDT']) {
          resData = await this.putActionRequireds(actionRequired, action);
        } else {
          resData = await this.postActionRequireds(actionRequired, action);
        }
        actionRequired['actDT'] = resData.actDT;
        actionRequired['acterId'] = resData.acterId;
        actionRequired['actDesc'] = resData.actDesc;
        actionRequired['actionRscFiles'] = resData.actionRscFiles;
        this.setCheckHistoryItemState();
        this.onDeleteEditingItemIdList(resData.id);
      } finally {
        this.isLoadingItemId = 0;
      }
    },
    setCheckHistoryItemState() {
      const isStatusNotTakenAction = this.checkHistoryItem['actionRequireds'].some((actionRequired) => {
        return !actionRequired['actDT'];
      });
      if (!isStatusNotTakenAction) {
        this.checkHistoryItem['chkHistItemState'] = 'Action';
      }
    },
    async putActionRequireds(actionRequired, action) {
      return await this.PUT_ACTION_REQUIREDS([actionRequired.id, action]);
    },
    async postActionRequireds(actionRequired, action) {
      return await this.POST_ACTION_REQUIREDS([actionRequired.id, action]);
    },
    async postActionRscFiles(rscFile) {
      try {
        const fileId = await this.POST_ACTION_RSC_FILES(rscFile);
        this.$set(this.selectedActionRequired, 'rscFileIds', [{ id: fileId }]);
        this.selectedActionRequired.actionRscFiles.unshift({
          id: fileId,
          rscUrl: this.imageUrl,
          name: this.allFilename,
          type: 'img',
        });
      } finally {
        this.allFilename = '';
        this.fileBase64 = '';
      }
    },
    getUserName(userId) {
      return getUserName(this.userNameList, userId);
    },
    isIncludeEditingItemId(id) {
      return this.editingItemIdList.includes(id);
    },
  },
  components: { GeneralFunctionPopup, ActionRequireImageBox, AudioPlay },
};
</script>

<style scoped lang="scss">
@import 'ActionRequirePopup';
</style>
