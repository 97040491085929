import XLSX from 'xlsx-color';
import { titleStyle, tableHeaderStyle } from '@/asset/js/downloadExcel/common/commonStyle';
import setTableStyle from '@/asset/js/downloadExcel/common/setTableStyle';
import setCalendarDateCell from '@/asset/js/downloadExcel/common/setCalendarDateCell';
import checkCycle from '@/const/checkCycle';

export default (excelName, equipmentName, selectedDT, lastDate, calendar) => {
  const TABLE_TITLE = [{ 0: '설비명', 1: `${equipmentName}` }, { 0: '기간', 1: `${selectedDT}` }, {}];
  const TABLE_DATA = [
    {
      0: '주기',
      1: '요약',
      2: '방법',
    },
  ];
  const TABLE_LEFT_DATA_LENGTH = Object.keys(TABLE_DATA[0]).length;

  setCalendarDateCell(TABLE_DATA, TABLE_LEFT_DATA_LENGTH, selectedDT, lastDate);

  calendar.forEach((calendarItem, i) => {
    TABLE_DATA[i + 1] = {};
    TABLE_DATA[i + 1][0] = checkCycle[calendarItem.chkCycle];
    TABLE_DATA[i + 1][1] = calendarItem.name;
    TABLE_DATA[i + 1][2] = calendarItem.chkMethodDesc;

    calendarItem.simpleDailyHists.forEach((histData, j) => {
      let changedData = '';
      switch (histData.chkHistItemState) {
        case 'Ok':
          changedData = 'V';
          break;
        case 'NotTakenAction':
          changedData = '조치 대기';
          break;
        case 'Action':
          changedData = '조치 완료';
          break;
        default:
          changedData = '';
          break;
      }

      TABLE_DATA[i + 1][j + TABLE_LEFT_DATA_LENGTH] = changedData;
    });
  });

  const EXCEL_FILE = XLSX.utils.book_new(); // 엑셀 파일 생성
  const WORK_SHEET = XLSX.utils.json_to_sheet([...TABLE_TITLE, ...TABLE_DATA], {
    skipHeader: true,
  }); // 시트 생성 (worksheet) 및 데이터 삽입

  WORK_SHEET['!cols'] = [{ wch: 10 }, { wch: 15 }, { wch: 30 }];
  WORK_SHEET.A1.s = titleStyle;
  WORK_SHEET.A2.s = titleStyle;

  setTableStyle({
    workSheet: WORK_SHEET,
    table: TABLE_DATA,
    headerStyle: tableHeaderStyle,
    startTableRowNum: TABLE_TITLE.length,
    cellColorByValueObj: { '조치 완료': '0000ff', '조치 대기': 'ff0000' },
    leftDataLength: TABLE_LEFT_DATA_LENGTH,
  });

  XLSX.utils.book_append_sheet(EXCEL_FILE, WORK_SHEET, 'sheet1'); // 엑셀 파일에 시트 추가
  XLSX.writeFile(EXCEL_FILE, excelName); // 엑셀 다운로드
};
