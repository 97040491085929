var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "general-function-popup",
        { staticClass: "action-require-multi-popup" },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [
              _c(
                "button",
                { staticClass: "hidden" },
                [
                  _c("vue-material-icon", {
                    attrs: { name: "keyboard_arrow_left", size: 30 },
                  }),
                ],
                1
              ),
              _c("h2", [_vm._v("점검 현황 상세")]),
              _c(
                "button",
                {
                  staticClass: "close-button",
                  on: { click: _vm.onClosePopup },
                },
                [
                  _c("vue-material-icon", {
                    attrs: { name: "close", size: 24 },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "contents",
              attrs: { slot: "contents" },
              slot: "contents",
            },
            [
              _c("ul", { staticClass: "action-status-list" }, [
                _c("li", [
                  _c("span", [_vm._v("점검 현황")]),
                  _c(
                    "span",
                    {
                      attrs: {
                        value: _vm.checkHistoryItem["chkHistItemState"],
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.checkHistoryItemStateName[
                              _vm.checkHistoryItem["chkHistItemState"]
                            ]
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("li", [
                  _c("span", [_vm._v("점검 항목")]),
                  _c("span", [
                    _vm._v(
                      "[" +
                        _vm._s(
                          _vm.checkRequiredTypeName[
                            _vm.checkHistoryItem["chkRequireType"]
                          ]
                        ) +
                        "] " +
                        _vm._s(_vm.checkHistoryItem["name"])
                    ),
                  ]),
                ]),
              ]),
              _c(
                "ul",
                { staticClass: "request-action-list" },
                _vm._l(
                  _vm.checkHistoryItem["actionRequireds"],
                  function (actionRequired, index) {
                    return _c("li", { key: index }, [
                      _c("div", { staticClass: "request" }, [
                        _c("h3", [_vm._v("특이 사항")]),
                        _c(
                          "div",
                          { staticClass: "request-action-box" },
                          [
                            _c("div", { staticClass: "status-header" }, [
                              _c("span", {
                                staticClass: "status-color-box",
                                class:
                                  _vm.actionStatusClassName(actionRequired),
                              }),
                              _c(
                                "span",
                                { staticClass: "text-overflow-ellipsis" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("localDateYYYYMMDDTime")(
                                          actionRequired["reqDT"]
                                        )
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.getUserName(
                                          actionRequired["requesterId"]
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            _vm.getFileInRscFiles(
                              actionRequired["actionRequiredRscFiles"],
                              "audio"
                            )
                              ? _c("audio-play", {
                                  attrs: {
                                    "audio-src": _vm.getFileInRscFiles(
                                      actionRequired["actionRequiredRscFiles"],
                                      "audio"
                                    ).rscUrl,
                                    descriptions: [
                                      "리얼웨어에서 입력된 특이사항은 음성 녹음 파일로 확인할 수 있습니다.",
                                    ],
                                  },
                                })
                              : _c("label", [
                                  _c("textarea", {
                                    attrs: { readonly: "" },
                                    domProps: {
                                      value: actionRequired["reqDesc"],
                                    },
                                  }),
                                ]),
                            _c(
                              "div",
                              {
                                staticClass: "image-wrapper",
                                class: {
                                  "none-image": !_vm.getFileInRscFiles(
                                    actionRequired["actionRequiredRscFiles"],
                                    "img"
                                  ),
                                },
                              },
                              [
                                !_vm.getFileInRscFiles(
                                  actionRequired["actionRequiredRscFiles"],
                                  "img"
                                )
                                  ? _c("p", [
                                      _vm._v(" 등록된 이미지가 없습니다. "),
                                    ])
                                  : _vm._e(),
                                _c("action-require-image-box", {
                                  staticClass: "background-image",
                                  attrs: {
                                    "rsc-file": _vm.getFileInRscFiles(
                                      actionRequired["actionRequiredRscFiles"],
                                      "img"
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "action" }, [
                        _c("div", { staticClass: "action-header" }, [
                          _c("h3", [_vm._v("조치")]),
                          _c(
                            "div",
                            [
                              _vm.isIncludeEditingItemId(
                                actionRequired["id"]
                              ) && actionRequired["actDT"]
                                ? _c(
                                    "button-basic",
                                    {
                                      attrs: {
                                        width: 60,
                                        height: 32,
                                        color: "blue-border-1",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickCancelAction(
                                            actionRequired
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 취소 ")]
                                  )
                                : _vm._e(),
                              _vm.isIncludeEditingItemId(actionRequired["id"])
                                ? _c(
                                    "button-basic",
                                    {
                                      attrs: {
                                        width: 60,
                                        height: 32,
                                        "loading-dot":
                                          _vm.isLoadingItemId ===
                                          actionRequired["id"],
                                        disabled: !_vm.isFilledAllRequiredField(
                                          actionRequired["actDesc"]
                                        ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickSaveAction(
                                            actionRequired
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 저장 ")]
                                  )
                                : _vm.showEditButton(actionRequired)
                                ? _c(
                                    "button-basic",
                                    {
                                      attrs: { width: 60, height: 32 },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickEditAction(
                                            actionRequired
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" 수정 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "request-action-box",
                            class: {
                              "action-edit-mode": _vm.isIncludeEditingItemId(
                                actionRequired["id"]
                              ),
                              "action-focus-mode":
                                _vm.isFocusedTextarea === actionRequired["id"],
                            },
                          },
                          [
                            !_vm.isIncludeEditingItemId(actionRequired["id"])
                              ? _c("div", { staticClass: "status-header" }, [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("localDateYYYYMMDDTime")(
                                            actionRequired["actDT"]
                                          )
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.getUserName(
                                            actionRequired["acterId"]
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.getFileInRscFiles(
                              actionRequired["actionRscFiles"],
                              "audio"
                            )
                              ? _c("audio-play", {
                                  attrs: {
                                    "audio-src": _vm.getFileInRscFiles(
                                      actionRequired["actionRscFiles"],
                                      "audio"
                                    ).rscUrl,
                                    descriptions: [
                                      "리얼웨어에서 입력된 조치 내용은 음성 녹음 파일로 확인할 수 있습니다.",
                                      "리얼웨어에서 음성 녹음 파일로 저장된 조치 내용은 수정할 수 없습니다.",
                                    ],
                                  },
                                })
                              : _c("label", [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: actionRequired["actDesc"],
                                        expression: "actionRequired['actDesc']",
                                      },
                                    ],
                                    attrs: {
                                      autocomplete: "off",
                                      maxlength: 500,
                                      placeholder: "조치한 내용을 입력하세요.",
                                      readonly: !_vm.isIncludeEditingItemId(
                                        actionRequired["id"]
                                      ),
                                    },
                                    domProps: {
                                      value: actionRequired["actDesc"],
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.toggleIsFocusedTextarea(
                                          actionRequired["id"]
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.toggleIsFocusedTextarea(0)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          actionRequired,
                                          "actDesc",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                            _c(
                              "div",
                              {
                                staticClass: "image-wrapper",
                                class: {
                                  "none-image": !_vm.getFileInRscFiles(
                                    actionRequired["actionRscFiles"],
                                    "img"
                                  ),
                                },
                              },
                              [
                                !_vm.getFileInRscFiles(
                                  actionRequired["actionRscFiles"],
                                  "img"
                                )
                                  ? [
                                      _vm.isIncludeEditingItemId(
                                        actionRequired["id"]
                                      )
                                        ? _c(
                                            "file-basic",
                                            {
                                              attrs: {
                                                id:
                                                  "action-file-" +
                                                  actionRequired.id,
                                                accept: ".jpg, .png",
                                                color: "dark",
                                                size: "s",
                                                width: 150,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onSelectFile(
                                                    $event,
                                                    actionRequired
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("plus-icon", {
                                                attrs: { size: 16 },
                                              }),
                                              _vm._v(" 이미지 등록 "),
                                            ],
                                            1
                                          )
                                        : _c("p", [
                                            _vm._v("등록된 이미지가 없습니다."),
                                          ]),
                                    ]
                                  : [
                                      _c("action-require-image-box", {
                                        staticClass: "background-image",
                                        attrs: {
                                          "rsc-file": _vm.getFileInRscFiles(
                                            actionRequired["actionRscFiles"],
                                            "img"
                                          ),
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getFileInRscFiles(
                                                actionRequired[
                                                  "actionRscFiles"
                                                ],
                                                "img"
                                              ).name
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm.isIncludeEditingItemId(
                                        actionRequired["id"]
                                      )
                                        ? _c(
                                            "button-basic",
                                            {
                                              staticClass: "delete-button",
                                              attrs: {
                                                color: "dark",
                                                circle: "18",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.onDeletePhoto(
                                                    actionRequired,
                                                    index,
                                                    _vm.getFileInRscFiles(
                                                      actionRequired[
                                                        "actionRscFiles"
                                                      ],
                                                      "img"
                                                    ).id
                                                  )
                                                },
                                              },
                                            },
                                            [_c("close-icon")],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  }
                ),
                0
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }