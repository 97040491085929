<template>
  <section class="main-section bg-gray60 check-history-detail-section">
    <main-section-header
      title="점검 이력 상세"
      :selectedItemName="selectedEqpNameCode"
      subtitle="선택한 설비의 점검 이력을 확인하고, 특이사항의 조치 내용을 입력할 수 있습니다."
      :hasBackButton="true"
      @back="onMovePrevPage"
    />
    <div class="container">
      <list-utility-menu>
        <drop-down-box
          slot="calendar"
          id="check-history-detail-month"
          :value="selectedDT"
          :optionItemsData="searchDateOptions"
          @selectedOption="onSelectedDT"
        />
        <button-basic
          slot="button"
          color="gray-border-1"
          size="s"
          :width="120"
          :disabled="isDisabledExcelButton"
          @click="onDownloadExcel"
        >
          <img src="/images/excel.png" alt="download excel" />
          엑셀 다운로드
        </button-basic>
      </list-utility-menu>
      <calendar-table :thead-list="theadList" :selected-date="selectedDT" :list="checkHistoryCalendarDetail">
        <li slot="contents-data">
          <ul
            v-for="(eqp, index) in checkHistoryCalendarDetail"
            :key="`contents-data-${eqp.sheetId}-${index}`"
            :class="{ 'is-inactive': !eqp.isActive }"
          >
            <li
              v-for="(dailyHist, index) in eqp.simpleDailyHists"
              :key="`contents-data-${eqp.sheetId}-${index}`"
              :style="{ width: `calc((100% / ${lastDate}))` }"
            >
              <span data-icon="check" v-if="dailyHist.chkHistItemState === 'Ok'">
                <vue-material-icon name="check" :size="24" />
                <span class="checker" :class="{ 'is-last-date': dailyHist.baseDate === lastDate }">
                  <span>점검자</span>
                  <span>{{ getCheckerName(dailyHist.checkerId) }}</span>
                </span>
              </span>
              <span :class="dailyHist.chkHistItemState" v-else @click="onClickActionStatus(dailyHist)">
                {{ chkHistItemStateName[dailyHist.chkHistItemState] }}
              </span>
            </li>
          </ul>
        </li>
        <ul
          slot="contents-label"
          v-for="(eqp, index) in checkHistoryCalendarDetail"
          :key="`contents-label-${index}`"
          :class="{ 'is-inactive': !eqp.isActive }"
        >
          <li :style="{ width: `${theadList[0].widthNumPercent}%` }" :title="`${eqp.chkCycle || '-'}`">
            {{ chkHistItemChkCycleName[eqp.chkCycle] || '-' }}
          </li>
          <li :style="{ width: `${theadList[1].widthNumPercent}%` }" :title="`${eqp.name || '-'}`">
            {{ eqp.name || '-' }}
          </li>
          <li :style="{ width: `${theadList[2].widthNumPercent}%` }" :title="`${eqp.chkMethodDesc || '-'}`">
            {{ eqp.chkMethodDesc || '-' }}
          </li>
        </ul>
        <p slot="no-list-description">선택하신 설비에 등록된 점검 항목이 없습니다.</p>
      </calendar-table>
    </div>
    <action-require-multi-popup
      :is-show="showedPopupName === 'ActionStatusPopup'"
      :checkHistoryItem="selectedCheckHistoryItem"
      :userNameList="userNameList"
      @close="onCloseActionStatusPopup"
    />
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import DropDownBox from '@/component/dropDown/DropDownBox';
import ListUtilityMenu from '@/component/list/ListUtilityMenu';
import CalendarTable from '@/component/calendarTable/CalendarTable';
import getLastDate from '@/asset/js/getLastDate';
import ActionRequireMultiPopup from '@/component/popup/actionRequirePopup/ActionRequireMultiPopup';
import checkHistoryDetailExcel from '@/asset/js/downloadExcel/checkHistoryDetailExcel';
import checkCycle from '@/const/checkCycle';
import getUserName from '@/asset/js/getUserName';

export default {
  name: 'CheckHistoryCalendarDetail',
  props: [],
  data() {
    return {
      selectedDT: this.$options.filters.localDateYYYYMM(new Date()),
      theadList: [
        { name: '주기', widthNumPercent: 10 },
        { name: '요약', widthNumPercent: 10 },
        { name: '방법', widthNumPercent: 25 },
        { name: '점검 현황', widthNumPercent: 55 },
      ],
      selectedEquipment: null,
      checkHistoryCalendarDetail: null,
      chkHistItemStateName: {
        NotTakenAction: '조치 대기',
        Action: '조치 완료',
      },
      chkHistItemChkCycleName: checkCycle,
      showedPopupName: '',
      selectedCheckHistoryItem: {},
      userNameList: [],
      eqpNameAndCode: '',
    };
  },
  computed: {
    ...mapState('chkHists', ['checkHistoryDateRange']),
    isDisabledExcelButton() {
      return !this.checkHistoryCalendarDetail;
    },
    searchDateOptions() {
      const date = new Date();
      const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
      const options = [];

      for (let i = 0; i < 6; i++) {
        options.push(this.$options.filters.localDateYYYYMM(firstDate));
        firstDate.setMonth(firstDate.getMonth() - 1);
      }

      return options;
    },
    selectedEqpNameCode() {
      return this.getSelectedEqpNameCode();
    },
    lastDate() {
      return getLastDate(this.selectedDT);
    },
    startDT() {
      return this.$options.filters.localDateYYYYMMDD(this.selectedDT);
    },
    endDT() {
      return this.$options.filters.localDateYYYYMMDD(`${this.selectedDT}-${this.lastDate}`);
    },
  },
  created() {
    this.initRenderData();
    this.getUserName();
    this.getEquipment();
  },
  mounted() {},
  watch: {
    $route() {
      this.initRenderData();
    },
  },
  methods: {
    ...mapMutations('chkHists', ['INIT_CHECK_HISTORY_DATE_RANGE']),
    ...mapActions('factoryEquipments', ['GET_FACTORY_EQP']),
    ...mapActions('factorySelfEquipments', ['GET_FACTORY_EQP_SELF']),
    ...mapActions('factoryUsers', ['GET_FACTORY_USER_NAME']),
    ...mapActions('chkHistItems', ['GET_CHECK_HISTORY_ITEMS_CALENDAR']),
    initRenderData() {
      const selectedDT = this.$route.query.selectedDT;
      if (selectedDT) {
        this.selectedDT = this.$options.filters.localDateYYYYMM(selectedDT);
      }
      this.getCheckHistoryCalendarDetail();
    },
    async getEquipment() {
      const eqpId = this.$route.params.eId;
      const mEqpId = this.$route.query.meId;
      if (mEqpId) {
        this.selectedEquipment = await this.GET_FACTORY_EQP(eqpId);
      } else {
        this.selectedEquipment = await this.GET_FACTORY_EQP_SELF(eqpId);
      }
    },
    async getUserName() {
      this.userNameList = await this.GET_FACTORY_USER_NAME();
    },
    async getCheckHistoryCalendarDetail() {
      try {
        this.checkHistoryCalendarDetail = await this.GET_CHECK_HISTORY_ITEMS_CALENDAR([
          this.$route.params.eId,
          this.startDT,
          this.endDT,
        ]);
        this.checkHistoryCalendarDetail.sort(function (a, b) {
          return a.isActive > b.isActive ? -1 : a.isActive < b.isActive ? 1 : 0;
        });
        await this.setSimpleDailyHistory();
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
    setSimpleDailyHistory() {
      this.checkHistoryCalendarDetail.forEach((eqp) => {
        let prevDate = 0;
        eqp.simpleDailyHists = [];

        eqp.dailyHistItemBoardVM.forEach((dailyHistItemBoard) => {
          const date = new Date(dailyHistItemBoard.baseDay).getDate();
          const value = {
            chkHistItemState: dailyHistItemBoard.chkHistItemState,
            actionRequireds: dailyHistItemBoard.actionRequireds,
            chkRequireType: dailyHistItemBoard.chkRequireType,
            name: eqp.name,
            checkerId: dailyHistItemBoard.checkerId,
            isActive: eqp.isActive,
            baseDate: date,
          };

          for (let i = prevDate + 1; i < date; i++) {
            eqp.simpleDailyHists.push('');
          }

          prevDate = date;
          eqp.simpleDailyHists.push(value);
        });

        for (let i = prevDate + 1; i <= this.lastDate; i++) {
          eqp.simpleDailyHists.push('');
        }
      });
    },
    onSelectedDT(selectedDT) {
      this.selectedDT = selectedDT;
      this.onMoveSearchedPage();
    },
    onMoveSearchedPage() {
      this.$router
        .push({
          name: 'CheckHistoryCalendarDetail',
          query: {
            ...this.$route.query,
            selectedDT: this.selectedDT || undefined,
          },
        })
        .catch((err) => err);
    },
    onMovePrevPage() {
      this.$router
        .push({
          name: 'CheckHistoryCalendar',
          query: {
            ...this.$route.query,
            selectedDT: this.checkHistoryDateRange.selectedDT,
          },
        })
        .catch((err) => err);
      this.INIT_CHECK_HISTORY_DATE_RANGE();
    },
    onClickActionStatus(checkHistoryItem) {
      if (checkHistoryItem.chkHistItemState && checkHistoryItem.isActive) {
        this.togglePopup('ActionStatusPopup');
        this.selectedCheckHistoryItem = checkHistoryItem;
      }
    },
    togglePopup(popupName) {
      this.showedPopupName = popupName || '';
    },
    onCloseActionStatusPopup() {
      this.togglePopup();
      this.getCheckHistoryCalendarDetail();
    },
    getCheckerName(checkerId) {
      return getUserName(this.userNameList, checkerId);
    },
    onDownloadExcel() {
      let excelName;
      excelName = `CheckHistoryDetail_Summary_${this.selectedDT}.xlsx`;
      checkHistoryDetailExcel(
        excelName,
        this.eqpNameAndCode,
        this.selectedDT,
        this.lastDate,
        this.checkHistoryCalendarDetail,
      );
    },
    getSelectedEqpNameCode() {
      if (!this.selectedEquipment) {
        return '';
      }
      this.eqpNameAndCode = `${this.selectedEquipment['equipmentName']} (${this.$options.filters.formatEQPCode({
        mEqpId: this.selectedEquipment['mequipmentId'],
        eqpId: this.selectedEquipment['id'],
      })})`;
      return this.eqpNameAndCode;
    },
  },
  components: { MainSectionHeader, ListUtilityMenu, DropDownBox, CalendarTable, ActionRequireMultiPopup },
};
</script>

<style scoped lang="scss">
@import 'CheckHistoryCalendarDetail';
</style>
